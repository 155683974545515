import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import type { Api } from 'utils/api';
import type { StateObservable } from 'redux-observable';
import type { AppState } from 'behavior';
import { PageComponentNames } from 'behavior/pages';
import { RouteName } from 'routes';
import { loadSystemPageQuery } from '../system/queries';
import { getBackTo, initComponent } from '../helpers';
import { initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { of } from 'rxjs';
import { map, switchMap, pluck, first } from 'rxjs/operators';
import { StoreType, areSettingsLoaded } from 'behavior/settings';

const handler: Handler<CreateAccountLandingPageRouteData, CreateAccountLandingPage> = (routeData, state$, { api }) => {
    return state$.pipe(
        pluck('settings'),
        first(areSettingsLoaded),
        switchMap(
            settings => settings.storeType === StoreType.Closed
                ? loadClosedStoreLoginPage(routeData, state$)
                : loadSystemLoginPage(routeData, state$, api),
        ),
    );
};

export default handler;

function loadClosedStoreLoginPage(routeData: CreateAccountLandingPageRouteData, state$: StateObservable<AppState>) {
    return of({
        emptyLayout: true,
        backTo: _getBackTo(routeData, state$),
    }).pipe(
        initComponent(PageComponentNames.ClosedStoreLogin),
    );
}

function loadSystemLoginPage(routeData: CreateAccountLandingPageRouteData, state$: StateObservable<AppState>, api: Api) {
    return api.graphApi<CreateAccountLandingPageResponse>(loadSystemPageQuery('createAccountLandingPage')).pipe(
        pluck('pages', 'createAccountLandingPage'),
        map(page => {
            if (!page)
                return null;

            return {
                page: {
                    component: PageComponentNames.CreateAccountLandingPage as const,
                    backTo: routeData.options && routeData.options.backTo || _getBackTo(routeData, state$),
                    ...page,
                },
            };
        }),
        initSystemPageContent(),
    );
}

function _getBackTo(routeData: CreateAccountLandingPageRouteData, state$: StateObservable<AppState>) {
    return getBackTo(state$, [
        RouteName.Login,
    ], routeData.params && routeData.params.language);
}

type PublicStoreCreateAccountLandingPage = SystemPage & {
    component: PageComponentNames.CreateAccountLandingPage;
    backTo?: BackTo;
};

type CreateAccountLandingPageRouteData = {
    routeName: RouteName.CreateAccountLandingPage;
    params?: {
        language: number;
    };
    options?: {
        backTo?: BackTo;
    };
};

type CreateAccountLandingPageResponse = {
    pages: {
        createAccountLandingPage: SystemPageData;
    };
};

type ClosedStoreCreateAccountLandingPage = {
    component: PageComponentNames.ClosedStoreLogin;
    emptyLayout: boolean;
    backTo?: BackTo;
};

type CreateAccountLandingPage = ClosedStoreCreateAccountLandingPage | PublicStoreCreateAccountLandingPage;
