import {
    CreateAccountAction,
    REGISTRATION_CONTACT_INFORMATION_PROCESSED,
    REGISTRATION_TEMPLATEFIELDS_RECEIVED,
    REGISTER_CONTACT_INFORMATION,
} from './actions';
import type { TemplateFields, RegistrationResult, RegistrationContactInformationData } from './types';

type State = {
    registrationResult: RegistrationResult;
    templateFields: TemplateFields | null;
    registrationData: RegistrationContactInformationData | null;
};

const initialState = {
    registrationResult: {
        failureText: null,
        isRegistered: false,
    },
    templateFields: null,
    registrationData: null,
};

export default (state = initialState, action: CreateAccountAction): State => {
    switch (action.type) {
        case REGISTER_CONTACT_INFORMATION:
            return { ...state, registrationData: action.payload };
        case REGISTRATION_CONTACT_INFORMATION_PROCESSED:
            return {
                ...state, registrationResult: { invalidInput:false }};
        case REGISTRATION_TEMPLATEFIELDS_RECEIVED:
            return { ...state, templateFields: action.payload };
        default:
            return state;
    }
};