import type { Handler } from '../types';
import type { Api } from 'utils/api';
import { verifyEmailPreRegisterAccountPageQuery, verifyEmailPreRegisterAccountDataQuery } from './queries';
import { mergeMap, switchMap, pluck, first, map } from 'rxjs/operators';
import { RouteName, routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { StoreType, areSettingsLoaded } from 'behavior/settings';
import { initPageContent, initSystemPageContent, SystemPage, SystemPageData } from '../system';
import { loadSystemPageQuery } from '../system/queries';

const handler: Handler<VerifyEmailPreRegisterAccountRouteData, VerifyEmailPreRegisterAccountPage> = (routeData, state$, { api }) => {
    return state$.pipe(
        pluck('settings'),
        first(areSettingsLoaded),
        switchMap(
            settings => settings.storeType === StoreType.Closed
                ? loadClosedStoreVerifyEmailPreRegisterAccountPage(routeData, api)
                : loadSystemVerifyEmailPreRegisterAccountPage(routeData, api),
        ),
    );
};

export default handler;

function loadClosedStoreVerifyEmailPreRegisterAccountPage(routeData: VerifyEmailPreRegisterAccountRouteData, api: Api) {
    if (routeData.params.previewToken)
        return loadClosedStoreVerifyEmailPreRegisterAccountPreviewPage();

    return api.graphApi<ClosedStoreVerifyEmailPreRegisterAccountResponse>(verifyEmailPreRegisterAccountDataQuery, { token: routeData.params.token }).pipe(
        mergeMap(({ profile }) => {
            if (!profile.verifyEmailPreRegisterAccountData)
                return of({ action$: of(navigateTo(routesBuilder.forAzureLoginError(), undefined, { replaceHistory: true })) });

            return of({
                page: {
                    emptyLayout: true,
                    component: PageComponentNames.ClosedStoreVerifyEmailPreRegisterAccount as const,
                    verifyEmailPreRegisterAccountData: profile.verifyEmailPreRegisterAccountData,
                    token: routeData.params.token,
                },
            });
        }),
    );
}

function loadSystemVerifyEmailPreRegisterAccountPage(routeData: VerifyEmailPreRegisterAccountRouteData, api: Api) {
    if (routeData.params.previewToken)
        return loadVerifyEmailPreRegisterAccountPreviewPage(api);

    return api.graphApi<PublicStoreVerifyEmailPreRegisterAccountResponse>(verifyEmailPreRegisterAccountPageQuery, { token: routeData.params.token }).pipe(
        mergeMap(({ profile: { verifyEmailPreRegisterAccountData }, pages }) => {
            if (!verifyEmailPreRegisterAccountData)
                return of({ action$: of(navigateTo(routesBuilder.forAzureLoginError(), undefined, { replaceHistory: true })) });

            const page = pages.verifyEmailPreRegisterAccount;
            if (!page)
                return of(null);

            const result = {
                ...page,
                component: PageComponentNames.VerifyEmailPreRegisterAccount as const,
                verifyEmailPreRegisterAccountData,
                token: routeData.params.token,
            };

            return of({ page: initPageContent(result) });
        }),
    );
}

function loadClosedStoreVerifyEmailPreRegisterAccountPreviewPage() {
    return of({
        page: {
            emptyLayout: true,
            component: PageComponentNames.ClosedStoreVerifyEmailPreRegisterAccount as const,
            token: '',
        },
    });
}

function loadVerifyEmailPreRegisterAccountPreviewPage(api: Api) {
    return api.graphApi<PublicStoreVerifyEmailPreRegisterAccountPreviewResponse>(loadSystemPageQuery('verifyEmailPreRegisterAccount')).pipe(
        map(({ pages: { verifyEmailPreRegisterAccount: page } }) => !page ? null : ({
            page: {
                ...page,
                component: PageComponentNames.VerifyEmailPreRegisterAccount as const,
                token: '',
            },
        })),
        initSystemPageContent(),
    );
}

type VerifyEmailPreRegisterAccountData = {
    name: string;
    email: string;
};

type VerifyEmailPreRegisterAccountRouteData = {
    routeName: RouteName.VerifyEmailPreRegisterAccount;
    params: {
        token: string;
        previewToken?: string;
        language?: number;
    };
};

type PublicStoreVerifyEmailPreRegisterAccountPage = SystemPage & {
    component: PageComponentNames.VerifyEmailPreRegisterAccount;
    verifyEmailPreRegisterAccountData?: VerifyEmailPreRegisterAccountData;
    token: string;
};

type ClosedStoreVerifyEmailPreRegisterAccountPage = {
    component: PageComponentNames.ClosedStoreVerifyEmailPreRegisterAccount;
    emptyLayout: boolean;
    verifyEmailPreRegisterAccountData?: VerifyEmailPreRegisterAccountData;
    token: string;
};

type VerifyEmailPreRegisterAccountPage = ClosedStoreVerifyEmailPreRegisterAccountPage | PublicStoreVerifyEmailPreRegisterAccountPage;

type ClosedStoreVerifyEmailPreRegisterAccountResponse = {
    profile: {
        verifyEmailPreRegisterAccountData: VerifyEmailPreRegisterAccountData | null;
    };
};

type PublicStoreVerifyEmailPreRegisterAccountResponse = {
    profile: {
        verifyEmailPreRegisterAccountData: VerifyEmailPreRegisterAccountData | null;
    };
    pages: {
        verifyEmailPreRegisterAccount: SystemPageData;
    };
};

type PublicStoreVerifyEmailPreRegisterAccountPreviewResponse = {
    pages: {
        verifyEmailPreRegisterAccount: SystemPageData;
    };
};
