import type { StateObservable } from 'redux-observable';
import type { Action } from 'redux';
import type { AppState } from 'behavior';
import type { StoreDependencies } from 'behavior/types';
import { viewerChanged, navigateTo } from 'behavior/events';
import { Observer, Observable, from, of } from 'rxjs';
import { unsetLoadingIndicator } from 'behavior/loadingIndicator';

export function createMapRegisterContactInformationResultInviteAzure() {
    return function (loginResult: { isRegistered: boolean }): Observable<Action> {
        if (loginResult.isRegistered) {

            return from([
                viewerChanged(),
                navigateTo(undefined, '/verify-email'),
            ]);
        }
        return of(unsetLoadingIndicator());
    };
}