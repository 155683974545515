exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "[data-hero-colored=true] .CallUsMessage_block,[data-hero-colored=true] .CallUsMessage_icon{color:inherit}.CallUsMessage_block{font-size:var(--theme-text_SmallerSize,12px);line-height:1.6;position:relative}.CallUsMessage_block .CallUsMessage_icon{bottom:0;left:0;margin-right:.18em;margin:auto;max-height:.33em;max-width:.33em;position:absolute;top:0;vertical-align:middle}.CallUsMessage_block>.header-icon{position:relative}.CallUsMessage_block.CallUsMessage_is-nav .CallUsMessage_icon{color:#4ba1d5;filter:invert(54%) sepia(96%) saturate(280%) hue-rotate(158deg) brightness(90%) contrast(93%)}.CallUsMessage_block.CallUsMessage_is-nav .header-icon{color:var(--theme-link_Color,#1f7bc9);font-size:var(--theme-text_Size,13px);padding-left:38px}@media not all and (min-width:env(--theme-breakpoints_Large,1280)px){html:not(.print) .CallUsMessage_block:not(.CallUsMessage_is-nav) .header-icon{overflow:hidden;text-indent:-1000px}}", ""]);

// exports
exports.locals = {
	"block": "CallUsMessage_block",
	"icon": "CallUsMessage_icon",
	"is-nav": "CallUsMessage_is-nav",
	"isNav": "CallUsMessage_is-nav"
};