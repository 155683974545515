import styles from '../MainNav.module.scss';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { CreateIcon } from 'components/objects/accountMenu/iconWithText';

const CreateUserBlock = () => {
  
  return (
    <Link to={routesBuilder.forCreateAccountLandingPage} className={styles.itemCustom}>
      <CreateIcon className={`${styles.icon}`} />
      <SimpleText textKey="LinkText_CreateAccount" />
    </Link>
  );
};

export default CreateUserBlock;
