import styles from '../AccountMenu.module.scss';
import PropTypes from 'prop-types';
import { CreateAccountIcon } from 'components/primitives/icons';

const CreateIcon = ({ className = '' }) => {
  return <CreateAccountIcon className={`${className} ${styles.createAccountIcon}`} />;
};

CreateIcon.propTypes = {
  className: PropTypes.string,
};

export default CreateIcon;
