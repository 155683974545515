exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ProductSuggestions_suggestions-btn{align-items:center;display:flex;justify-content:center;margin:0 25px 15px;min-height:auto;min-width:auto;padding:7px 10px}.ProductSuggestions_suggestions-btn .ProductSuggestions_icon{max-height:.28em;max-width:.28em}.ProductSuggestions_suggestions-btn.ProductSuggestions_hide-label{display:inline-flex;height:44px;margin:0;padding:0;width:44px}.ProductSuggestions_suggestions-btn.ProductSuggestions_hide-label .ProductSuggestions_icon{max-height:20px;max-width:20px}.ProductSuggestions_suggestions-btn .btn-cnt{margin-left:.6em;padding-top:0}", ""]);

// exports
exports.locals = {
	"suggestions-btn": "ProductSuggestions_suggestions-btn",
	"suggestionsBtn": "ProductSuggestions_suggestions-btn",
	"icon": "ProductSuggestions_icon",
	"hide-label": "ProductSuggestions_hide-label",
	"hideLabel": "ProductSuggestions_hide-label"
};