export const VERIFY_EMAIL_PREREGISTER_ACCOUNT_REQUESTED = 'VERIFY_EMAIL_PREREGISTER_ACCOUNT_REQUESTED' as const;
export const verifyEmailPreRegisterAccount = (token: string) => ({
    type: VERIFY_EMAIL_PREREGISTER_ACCOUNT_REQUESTED,
    payload: { token },
});

export const VERIFY_EMAIL_PREREGISTER_ACCOUNT_RESULT = 'VERIFY_EMAIL_PREREGISTER_ACCOUNT_RESULT' as const;
export const notifyVerifyResult = (success: boolean) => ({ type: VERIFY_EMAIL_PREREGISTER_ACCOUNT_RESULT, payload: { success } });

export type VerifyEmailPreRegisterAccountAction = ReturnType<
    | typeof verifyEmailPreRegisterAccount
    | typeof notifyVerifyResult
>;