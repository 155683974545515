import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { from, of, merge, concat } from 'rxjs';
import { switchMap, mergeMap,takeUntil, startWith} from 'rxjs/operators';
import { verifyAccountProcessed, VERIFY_ACCOUNT, VerifyAccountAction, verifyAccountFailed } from './actions';
import { navigateTo } from 'behavior/events';
import { verifyAccountMutation } from './queries';
import { LOCATION_CHANGED } from 'behavior/events';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { routesBuilder } from 'routes';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { unlockForm, FormName } from 'behavior/pages';
import { resetCaptcha } from 'behavior/captcha';

const setLoading = setLoadingIndicator();
const unsetLoading = unsetLoadingIndicator();

const verifyAccountEpic: Epic<VerifyAccountAction> = (action$, state$, deps) => {
    const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

    const verifyAccountAction$ = action$.pipe(
        ofType(VERIFY_ACCOUNT),
        switchMap(action => deps.api.graphApi<VerifyAccountUser>(verifyAccountMutation, {
            input: action.payload.verifyData,
        }, { retries: 0, useCookies: true }).pipe(
            mergeMap(result => {
                if (result.profile.verifyAccount && result.profile.verifyAccount.isMatchSuccessfull) {
                    return from([verifyAccountProcessed(result.profile.verifyAccount), navigateTo(routesBuilder.forVerifyAccount())]);
                }
                else {
                    const unlockFormAction = of(unlockForm(FormName.CreateAccountLandingPage));
                    return concat(
                        of(resetCaptcha(FormName.CreateAccountLandingPage), unsetLoadingIndicator(), verifyAccountFailed(result.profile.verifyAccount)),
                        unlockFormAction
                    )
                }
            }),
            catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(FormName.CreateAccountLandingPage), unlockForm(FormName.CreateAccountLandingPage), unsetLoading)),
            retryWithToast(action$, deps.logger, _ => of(resetCaptcha(FormName.CreateAccountLandingPage), unlockForm(FormName.CreateAccountLandingPage), unsetLoading)),
            takeUntil(locationChanged$),
            startWith(setLoading),
        )),
    );

    return merge(
        verifyAccountAction$,
    );
};

export default verifyAccountEpic;

type VerifyAccountUser = {
    profile: {
        verifyAccount: {
            isRegister: boolean;
            isMatchSuccessfull: boolean;
            name: string | null;
            address: string | null;
            accountNo: string | null;
        };
    };
};