import { VERIFY_EMAIL_PREREGISTER_ACCOUNT_RESULT, VerifyEmailPreRegisterAccountAction } from './actions';

type State = {
  result: {
    success: boolean;
  };
};

export default function verifyEmailPreRegisterAccountReducer(state: State, action: VerifyEmailPreRegisterAccountAction) {
  switch (action.type) {
    case VERIFY_EMAIL_PREREGISTER_ACCOUNT_RESULT:
      return {
        ...state,
        result: action.payload,
      };
    default:
      return state;
  }
}
