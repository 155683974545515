import styles from './AccountMenu.module.scss';
import PropTypes from 'prop-types';
import { useState, useEffect, useCallback,useRef } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Icon, CreateIcon } from './iconWithText';
import AccountMenuItems from './accountMenuItems';
import { ModalDrawer } from 'components/primitives/modalDrawer';
import { getThemeFontSizeClassName } from 'components/theme';
import { isBrowser } from 'utils/detections';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';

const slidingDuration = 200;

const MobileAccountMenuBlock = ({ model, isDesignerMode, id }) => {
  const [expanded, setExpanded] = useState(false);
  const { isLoading, isAuthenticated, isImpersonating, shopAccountType } = useSelector(state => state.user);
  const accountMenuId = `MobileAccountMenu_${id}`;
  const ref = useRef();

  useEffect(() => {
    if (!isLoading)
      return;

    setExpanded(false);
  }, [isLoading]);

  const handleInfoItemsClick = useCallback(({ target: { tagName } }) => {
    if (tagName !== 'A' && tagName !== 'BUTTON')
      return;

    setExpanded(false);
  }, []);

  const handleClose = useCallback(() => setExpanded(false), []);

  const iconClassName = `AccountMenu-icon_${id}`;
  const createIconClassName = `CreateAccountMenu-icon_${id}`;

  const style = {
    '--content-fontColor': model.content_FontColor,
    '--content-linkFontColor': model.content_LinkFontColor,
    '--content-backgroundColor': model.content_BackgroundColor,
    '--content-separatorsColor': model.content_SeparatorsColor,
  };

  return (
    <div className={styles.mobileAccountMenuCustom}>
      { !isAuthenticated &&
        <button
          className={`${styles.openerBtn} ${styles.createAccountBtnCustom}`}
          style={{ '--toggle-iconColor': model.toggle_IconColor }}
          suppressHydrationWarning
        >
          <Link
            to={routesBuilder.forCreateAccountLandingPage}
            ref={ref}
          >
            <CreateIcon className={`${styles.icon} ${createIconClassName}`} />
          </Link>
        </button>
      }

      <button
        className={styles.openerBtn}
        onClick={() => setExpanded(!expanded)}
        aria-controls={isBrowser && !isDesignerMode ? accountMenuId : null}
        style={{ '--toggle-iconColor': model.toggle_IconColor }}
        suppressHydrationWarning
      >
        <Icon isImpersonating={isImpersonating} className={`${styles.icon} ${iconClassName}`} />
        {model.content_ShowExpandIcon &&
          <span className={styles.caret}>
            <FontAwesomeIcon icon={faCaretDown} />
          </span>
        }
      </button>
      {isBrowser && !isDesignerMode && (
        <ModalDrawer
          expanded={expanded}
          onClick={handleInfoItemsClick}
          onClose={handleClose}
          id={accountMenuId}
          className={`${styles.drawer} ${getThemeFontSizeClassName(model.content_ThemeFontSize)}`}
          containerClassName={styles.drawerContainer}
          style={style}
        >
          <AccountMenuItems
            isAuthenticated={isAuthenticated}
            isImpersonating={isImpersonating}
            shopAccountType={shopAccountType}
            logoutDelay={slidingDuration}
          />
        </ModalDrawer>
      )}
    </div>
  );
};

MobileAccountMenuBlock.propTypes = {
  model: PropTypes.shape({
    toggle_IconColor: PropTypes.string,
    content_ShowExpandIcon: PropTypes.bool.isRequired,
    content_ThemeFontSize: PropTypes.string.isRequired,
    content_FontColor: PropTypes.string,
    content_LinkFontColor: PropTypes.string,
    content_BackgroundColor: PropTypes.string,
    content_SeparatorsColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

export default MobileAccountMenuBlock;
