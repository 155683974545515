import type { Handler } from 'behavior/pages/types';
import type { SystemPage } from 'behavior/pages/system';
import type { RouteData } from 'routes';
import { tap } from 'rxjs/operators';
import { createSystemPageHandler } from 'behavior/pages/system';
import { RouteName } from 'routes';
import { PageComponentNames } from 'behavior/pages';

const systemHandler = createSystemPageHandler();

const blanketSalesOrderSummaryHandler: Handler<BlanketSalesOrderSummaryRouteData, BlanketSalesOrderSummaryPage> = (routeData, $state, dependencies) => {
  const previewToken = routeData.params?.previewToken;

  return systemHandler(routeData, $state, dependencies)
    .pipe(
      tap(data => {
        if (data && data.page) {

          if (previewToken) {
            data.page.blanketSalesOrderLineSummary = {
                list: Array.from(Array(5)).map((_, index) => ({
                    itemNo: index.toString(),
                    description: '',
                    startingDate: '-',
                    ending: '-',
                    totalAgreementQuantity: '',
                    totalActualOrderQuantity: '',
                    totalQuantityShipped: '',
                    totalOpenQuantity: '',
              })),
              totalCount: 5,
            };
          }
        }
      }),
    );
};

export default blanketSalesOrderSummaryHandler;

type BlanketSalesOrderSummaryRouteData = RouteData & {
  routeName: RouteName.BlanketSalesOrderSummary;
  params: {
    previewToken?: string;
  };
  options?: {
    backToScrollPosition?: {
      screenWidth: number;
      value: number;
    };
  };
  canonicalUrl?: string;
  language?: {
    name: string;
  };
};

type BlanketSalesOrderSummaryPage = SystemPage & {
  component: PageComponentNames;
  blanketSalesOrderLineSummary?: {
    list: {
        itemNo: string;
        description: string;
        startingDate: string | null;
        ending: string | null;
        totalAgreementQuantity: string | null;
        totalActualOrderQuantity: string | null;
        totalQuantityShipped: string | null;
        totalOpenQuantity: string | null;
    }[];
    totalCount: number;
  };
};