export const AGREEMENT_APPLY = 'AGREEMENT_APPLY' as const;
export const applyAgreement = (salesAgreementId: string) => ({
  type: AGREEMENT_APPLY,
  payload: { salesAgreementId },
});

export const AGREEMENT_CANCEL = 'AGREEMENT_CANCEL' as const;
export const cancelAgreement = () => ({
  type: AGREEMENT_CANCEL,
});

export const AGREEMENT_APPLIED = 'AGREEMENT_APPLIED' as const;
export const agreementApplied = (salesAgreementId: string) => ({
  type: AGREEMENT_APPLIED,
  payload: { salesAgreementId },
});

export const AGREEMENT_CANCELED = 'AGREEMENT_CANCELED' as const;
export const agreementCanceled = () => ({
  type: AGREEMENT_CANCELED,
});

export const AGREEMENT_TERMS_APPLY = 'AGREEMENT_TERMS_APPLY' as const;
export const applyAgreementTerms = () => ({
  type: AGREEMENT_TERMS_APPLY,
});

export const AGREEMENT_SAVE = 'AGREEMENT_SAVE' as const;
export const saveAgreement = (lines: SalesAgreementSaveInput, updatedById: string | undefined, agreementId: string ) => ({
    type: AGREEMENT_SAVE,
    payload: { lines, updatedById, agreementId },
});

export const AGREEMENT_SAVED = 'AGREEMENT_SAVED' as const;
export const savedAgreement = () => ({
    type: AGREEMENT_SAVED,
});

export const AGREEMENT_SAVED_RESET = 'AGREEMENT_SAVED_RESET' as const;
export const savedAgreementReset = () => ({
    type: AGREEMENT_SAVED_RESET,
});

type SalesAgreementSaveInput = {
    documentId: string;
    salesAgreementItem: SalesAgreementItem[];
};

type SalesAgreementItem = {
    lineNo: string;
    quantity: number;
    shipmentDate?: Date;
};

export type AgreementAppliedAction = ReturnType<typeof agreementApplied>;
export type SalesAgreementAction =
  | AgreementAppliedAction
  | ReturnType<
    | typeof applyAgreement
    | typeof cancelAgreement
    | typeof agreementCanceled
    | typeof applyAgreementTerms
    | typeof saveAgreement
    | typeof savedAgreement
    | typeof savedAgreementReset
  >;
