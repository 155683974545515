import type { Epic } from 'behavior/types';
import { REGISTER_CONTACT_INFORMATION, registrationContactInformationProcessed, CreateAccountAction } from './actions';
import { registrationContactInformationMutation } from './queries';
import { unlockForm, FormName } from 'behavior/pages';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { ofType } from 'redux-observable';
import { of, EMPTY, concat } from 'rxjs';
import { switchMap, mergeMap, startWith } from 'rxjs/operators';
import { resetCaptcha } from 'behavior/captcha';
import { createMapRegisterContactInformationResultInviteAzure } from './helpers';

const setLoading = setLoadingIndicator();
const unsetLoading = unsetLoadingIndicator();

const registrationContactInformationEpic: Epic<CreateAccountAction> = (action$, state$, deps) => {
    const mapContactInformationResult = createMapRegisterContactInformationResultInviteAzure();

    return action$.pipe(
        ofType(REGISTER_CONTACT_INFORMATION),
        switchMap(({ payload: registrationData }) => deps.api.graphApi<RegistrationResult>(registrationContactInformationMutation, {
            input: registrationData,
        }, { retries: 0, useCookies: true }).pipe(
            mergeMap(result => {
                const { isRegistered } = result.profile.registerAccountInformation;
                
                const unlockFormAction = isRegistered
                    ? EMPTY
                    : of(unlockForm(FormName.ContactInformation));

                return concat(
                    of(resetCaptcha(FormName.ContactInformation), registrationContactInformationProcessed(result.profile.registerAccountInformation)),
                    unlockFormAction,
                    mapContactInformationResult(result.profile.registerAccountInformation),
                );
            }),
            catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(FormName.ContactInformation), registrationContactInformationProcessed({ invalidInput: true }), unlockForm(FormName.ContactInformation ), unsetLoading)),
            retryWithToast(action$, deps.logger, _ => of(resetCaptcha(FormName.ContactInformation), registrationContactInformationProcessed({ invalidInput: false }), unlockForm(FormName.ContactInformation), unsetLoading)),
            startWith(setLoading),
        )),
    );
};

type RegistrationResult = {
    profile: {
        registerAccountInformation: {
            failureText: string | null;
            isRegistered: boolean;
        };
    };
};

export default registrationContactInformationEpic;