import type { Epic } from 'behavior/types';
import type { SalesAgreementAction } from './actions';
import type { StoreDependencies } from 'behavior/types';
import { ofType } from 'redux-observable';
import { merge, of, EMPTY } from 'rxjs';
import { switchMap, mergeMap, startWith } from 'rxjs/operators';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import {
  AGREEMENT_APPLY,
  AGREEMENT_CANCEL,
  agreementApplied,
  agreementCanceled,
  savedAgreement,
  AGREEMENT_APPLIED,
  AGREEMENT_TERMS_APPLY,
  AGREEMENT_SAVE,
} from './actions';
import {
  applySalesAgreementMutation,
  cancelSalesAgreementMutation,
  applyTermsAutomaticallyMutation,
  saveSalesAgreementMutation,
} from './queries';
import { retryWithToast } from 'behavior/errorHandling';
import { navigateTo, basketChangeCompleted } from 'behavior/events';
import { RouteName, routesBuilder } from 'routes';
import {
  modifyBasket,
  BASKET_REMOVE_AGREEMENT,
} from 'behavior/basket/actions';
import { Updaters } from 'behavior/basket/constants';
import { skipIfPreviewWithToast } from 'behavior/preview';

type SalesAgreementMutationResponse = {
  salesAgreement: {
    apply: { success: boolean };
  } & {
    cancel: { success: boolean };
  } & {
    applyTermsAutomatically: { success: boolean };
  } & {
    save: { success: boolean };
  };
};

const agreementsEpic: Epic<SalesAgreementAction> = (action$, state$, { api, logger }) => {
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const apply$ = action$.pipe(
    ofType(AGREEMENT_APPLY),
    skipIfPreviewWithToast(state$, { api } as StoreDependencies),
    switchMap(({ payload }) =>
      api.graphApi<SalesAgreementMutationResponse>(applySalesAgreementMutation, { agreementId: payload.salesAgreementId }).pipe(
        mergeMap(({ salesAgreement }) => {
          if (salesAgreement.apply.success)
            return [
              agreementApplied(payload.salesAgreementId),
              basketChangeCompleted({ updaterId: Updaters.SalesAgreement, date: Date.now(), linesAmount: 0 }),
            ];

          throw new Error(`Sales agreement with ID/Title: "${payload.salesAgreementId}" is not available anymore.`);
        }),
      ),
    ),
    );

    const save$ = action$.pipe(
    ofType(AGREEMENT_SAVE),
    skipIfPreviewWithToast(state$, { api } as StoreDependencies),
    switchMap(({ payload }) =>
        api.graphApi<SalesAgreementMutationResponse>(saveSalesAgreementMutation, { lines: payload.lines ,agreementId: payload.agreementId }).pipe(
          mergeMap(({ salesAgreement }) => {
              if (salesAgreement.save.success)
              {
                  return [
                      savedAgreement(),
                      navigateTo(routesBuilder.forSalesAgreements()),
                  ];
              }
          throw new Error(`Sales agreement with ID/Title: "${payload.agreementId}" is not available anymore.`);
        }),
            startWith(setLoading),
        ),
    ),
  );

  const cancel$ = action$.pipe(
    ofType(AGREEMENT_CANCEL),
    switchMap(() =>
      api.graphApi<SalesAgreementMutationResponse>(cancelSalesAgreementMutation).pipe(
        mergeMap(() => [
          agreementCanceled(),
          basketChangeCompleted({ updaterId: Updaters.SalesAgreement, date: Date.now(), linesAmount: 0 }),
        ]),
      ),
    ),
  );

  const appliedAgreement$ = action$.pipe(
    ofType(AGREEMENT_APPLIED),
    switchMap(() => {
      const { routing } = state$.value;
      const routeData = routing.routeData;
      const routeName = routeData && routeData.routeName;

      if (routeName === RouteName.SalesAgreements)
        return of(navigateTo(routesBuilder.forBasket()));

      return EMPTY;
    }),
  );

  const applyTermsAutomatically$ = action$.pipe(
    ofType(AGREEMENT_TERMS_APPLY),
    switchMap(() =>
      api.graphApi<SalesAgreementMutationResponse>(applyTermsAutomaticallyMutation).pipe(
        mergeMap(() => [unsetLoading, modifyBasket([])]),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      ),
    ),
  );

  const removeAgreement$ = action$.pipe(
    ofType(BASKET_REMOVE_AGREEMENT),
    switchMap(() =>
      api.graphApi<SalesAgreementMutationResponse>(cancelSalesAgreementMutation).pipe(
        mergeMap(() => [unsetLoading, modifyBasket([])]),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      ),
    ),
  );

  return merge(apply$, cancel$, appliedAgreement$, applyTermsAutomatically$, removeAgreement$, save$);
};

export default agreementsEpic;
