import { ofType } from 'redux-observable';
import { VERIFY_EMAIL_PREREGISTER_ACCOUNT_REQUESTED, notifyVerifyResult, VerifyEmailPreRegisterAccountAction } from './actions';
import { exhaustMap, map, pluck, startWith } from 'rxjs/operators';
import { verifyEmailPreRegisterAccountMutation } from './queries';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { Epic } from 'behavior/types';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { of } from 'rxjs';

const setLoading = setLoadingIndicator();
const unsetLoading = unsetLoadingIndicator();

type VerifyEmailPreRegisterAccountResponse = {
  profile: {
    verifyEmailPreRegisterAccount: {
      verified: boolean;
    };
  };
};

const verifyEmailPreRegisterAccountEpic: Epic<VerifyEmailPreRegisterAccountAction> = (action$, _state$, deps) => {
  return action$.pipe(
    ofType(VERIFY_EMAIL_PREREGISTER_ACCOUNT_REQUESTED),
      exhaustMap(({ payload }) => deps.api.graphApi<VerifyEmailPreRegisterAccountResponse>(verifyEmailPreRegisterAccountMutation, { input: payload.token }).pipe(
      pluck('profile', 'verifyEmailPreRegisterAccount', 'verified'),
      map(notifyVerifyResult),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoading)),
      retryWithToast(action$, deps.logger, _ => of(unsetLoading)),
      startWith(setLoading),
    )),
    );
};

export default verifyEmailPreRegisterAccountEpic;
