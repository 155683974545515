import type {
    BlanketSalesOrderSummary,
    BlanketSalesOrderSummaryState,
} from './types';
import { BlanketSalesOrderSummaryAction } from './actions';
import { createReducer } from 'utils/redux';
import {
  BLANKET_SALES_SUMMARY_RECEIVED,
} from './actions';

export default createReducer<BlanketSalesOrderSummaryState, BlanketSalesOrderSummaryAction>({}, {
  [BLANKET_SALES_SUMMARY_RECEIVED]: onAgreementsReceived,

});

function onAgreementsReceived(state: BlanketSalesOrderSummaryState, { payload }: { payload: { blanketSalesOrderLineSummary: BlanketSalesOrderSummary[] | null; totalCount: number; append: boolean } }) {
  const newBlanketSalesOrderSummary = payload.blanketSalesOrderLineSummary || [];

  const items = payload.append && state.blanketSalesOrderLineSummary
    ? state.blanketSalesOrderLineSummary.items.concat(newBlanketSalesOrderSummary)
    : newBlanketSalesOrderSummary;

  const blanketSalesOrderLineSummary = {
    items,
    totalCount: payload.totalCount,
  };

  return { ...state, blanketSalesOrderLineSummary };
}


