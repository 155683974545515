import styles from '../MainNav.module.scss';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const texts = ['ContactUsLink'];

const CommentsBlock = () => {
  const { texts: [contactUsLinkText] } = useSanaTexts(texts, makeSimpleText);

  return (
    <Link url={contactUsLinkText} className={styles.itemCustom}>
      <span className={styles.iconCommentsCustom} />
      <SimpleText textKey="ContactUsNavMobile" />
    </Link>
  );
};

export default CommentsBlock;
