import PropTypes from 'prop-types';
import VideoThumbnail from './VideoThumbnail';
import { ProductMediaType } from 'behavior/pages/product';

const Thumbnail = ({ item, active }) => {
  return item.type === ProductMediaType.Video
    ? <VideoThumbnail item={item} />
    : <img src={item.small} alt={item.title} title={item.title} draggable="false" />;
};

Thumbnail.propTypes = {
  item: PropTypes.shape({
    small: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.oneOf([ProductMediaType.Video, ProductMediaType.Image]),
  }),
  active: PropTypes.bool,
};

export default Thumbnail;
