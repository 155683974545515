import styles from '../MainNav.module.scss';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

const texts = ['FreeConsultationLink'];

const FreeConsultationBlock = () => {
  const { texts: [freeConsultationLinkText] } = useSanaTexts(texts, makeSimpleText);

  return (
    <Link url={freeConsultationLinkText} className={styles.itemCustom}>
      <span className={styles.iconFreeConsultationCustom} />
      <SimpleText textKey="FreeConsultationNavMobile" />
    </Link>
  );
};

export default FreeConsultationBlock;
