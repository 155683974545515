import {
    VerifyAccountAction,
    VERIFY_ACCOUNT_PROCESSED,
    VERIFY_ACCOUNT_FAILED,
} from './actions';

type InitialState = {
    accountInformation: undefined;
};

type State = InitialState | {
    accountInformation: AccountInformationInfo | null;
};

type AccountInformationInfo = {
    isRegister: boolean;
    isMatchSuccessfull: boolean;
    name: string | null;
    address: string | null;
};

const initialState = {
    accountInformation: null,
};

export default (state = initialState, action: VerifyAccountAction): State => {
    switch (action.type) {
        case VERIFY_ACCOUNT_PROCESSED:
            return { ...state, accountInformation: { ...action.payload } };
        case VERIFY_ACCOUNT_FAILED:
            return { ...state, accountInformation: { ...action.payload } };
        default:
            return state;
    }
};