import type {
  BlanketSalesOrderSummaryAction,
  BlanketSalesOrderSummaryListRequestAction,
} from './actions';
import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { merge, of } from 'rxjs';
import {
  switchMap,
  mergeMap,
  startWith,
  filter,
} from 'rxjs/operators';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { retryWithToast } from 'behavior/errorHandling';
import {
  BLANKET_SALES_SUMMARY_REQUESTED,
  notifyBlanketSalesOrderSummaryReceived,
} from './actions';
import {
    blanketSalesOrderSummaryListQuery,
} from './queries';
import { skipIfPreviewWithToast } from 'behavior/preview';

const isAgreementListRequest = (action: BlanketSalesOrderSummaryListRequestAction): action is BlanketSalesOrderSummaryListRequestAction => {
  const payload = (action as BlanketSalesOrderSummaryListRequestAction).payload;
  return payload.index !== undefined && payload.size !== undefined;
};

const blanketSalesOrderSummaryPageEpic: Epic<BlanketSalesOrderSummaryAction> = (action$, state$, dependencies) => {
  const { api, logger } = dependencies;
  const setLoading = setLoadingIndicator();
  const unsetLoading = unsetLoadingIndicator();

  const onBlanketSalesOrderLineSummaryListRequested$ = action$.pipe(
    ofType(BLANKET_SALES_SUMMARY_REQUESTED),
    filter(isAgreementListRequest),
    skipIfPreviewWithToast(state$, dependencies),
    switchMap(({ payload }) => {
      const params = {
        input: {
          page: { index: payload.index, size: payload.size },
        },
      };

      return api.graphApi<BlanketSalesOrderSummaryListResponse>(blanketSalesOrderSummaryListQuery, params).pipe(
        mergeMap(({ blanketSalesOrderSummary }) =>
            [unsetLoading, notifyBlanketSalesOrderSummaryReceived(blanketSalesOrderSummary && blanketSalesOrderSummary.list, blanketSalesOrderSummary && blanketSalesOrderSummary.totalCount, payload.index ? true : false)],
        ),
        retryWithToast(action$, logger, _ => of(unsetLoading)),
        startWith(setLoading),
      );
    }),
  );

  
  return merge(
      onBlanketSalesOrderLineSummaryListRequested$,
  );
};

export default blanketSalesOrderSummaryPageEpic;

type BlanketSalesOrderSummaryListResponse = {
  blanketSalesOrderSummary: {
    totalCount: number;
    list: {
      itemNo: string;
      description: string;
      startingDate: string | null;
      ending: string | null;
      totalAgreementQuantity: string | null;
      totalActualOrderQuantity: string | null;
      totalQuantityShipped: string | null;
      totalOpenQuantity: string | null;
    }[] | null;
  };
};
