import styles from './MediaGallery.module.scss';
import { useRef, memo, useState, useLayoutEffect, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Gallery } from './gallery';
import GalleryControls from './GalleryControls';
import { Thumbnails } from './thumbnails';
import { usePrintMode } from 'utils/hooks';
import { useModal } from 'components/objects/modals';
import LargeGallery from './LargeGallery/LargeGallery';
import { ProductMediaType } from 'behavior/pages/product';
import { pauseVideo } from 'utils/video';

const MediaGallery = ({ items, index, bounds, noImageLarge, preventZoom }) => {
  const isPrintMode = usePrintMode();
  const [activeIndex, setActiveIndex] = useState(index < 0 ? 0 : index);
  const galleryPlayerRef = useRef(null);
  const itemsList = filterItems(items);
  const currentItem = itemsList[activeIndex];
  const itemsNumber = itemsList.length;
  const largeGallery = useModal();
  const shouldShowControl = currentItem
    && (currentItem.type === ProductMediaType.Video || (currentItem.type === ProductMediaType.Image && !!currentItem.large));

  const showControls = useRef(shouldShowControl);
  if (shouldShowControl)
    showControls.current = true;

  const goTo = index => {
    if (index === activeIndex)
      return;
    if (index < 0 || index >= itemsNumber)
      return;
    setActiveIndex(index);
  };

  useEffect(() => {
    galleryPlayerRef.current = null;
  }, [activeIndex]);

  useLayoutEffect(() => {
    goTo(index);
  }, [index]);

  const openLargeGallery = useCallback(() => {
    if (galleryPlayerRef.current)
      pauseVideo(galleryPlayerRef.current);

    largeGallery.show();
  }, []);

  const onPlayerChange = useCallback(player => {
    galleryPlayerRef.current = player;
  }, []);

  return (
    <>
      <div className={styles.mediaGallery}>
        {itemsNumber > 1 &&
          <Thumbnails items={itemsList} index={activeIndex} onChange={goTo} bounds={bounds} />
        }
        <div className={styles.mediaGalleryCnt}>
          <Gallery
            items={itemsList}
            index={activeIndex}
            onChange={goTo}
            noImage={noImageLarge}
            showLargeGallery={(!isPrintMode && currentItem && currentItem.large) ? largeGallery.show : null}
            onPlayerChange={onPlayerChange}
            preventZoom={preventZoom}
          />
          {!!itemsNumber && showControls.current && !isPrintMode &&
            <GalleryControls item={currentItem} openLargeGallery={openLargeGallery} />
          }
        </div>
      </div>
      {largeGallery.opened && <LargeGallery hide={largeGallery.hide} items={itemsList} index={activeIndex} />}
    </>
  );
};

MediaGallery.propTypes = {
  items: PropTypes.array.isRequired,
  index: PropTypes.number,
  noImageLarge: PropTypes.string.isRequired,
  preventZoom: PropTypes.bool,
  bounds: PropTypes.object,
};

export default memo(MediaGallery);

function filterItems(items) {
  return items
    .map(item => {
      if (item.type === ProductMediaType.Video || item.small)
        return item;

      const altSmall = item.medium || item.large;
      if (altSmall)
        return { ...item, small: altSmall };

      return null;
    })
    .filter(item => !!item);
}
