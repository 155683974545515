import type { RegistrationResult, TemplateFields, RegistrationContactInformationData } from './types';

export const REGISTRATION_CONTACT_INFORMATION_PROCESSED = 'REGISTRATION_CONTACT_INFORMATION_PROCESSED' as const;
export const registrationContactInformationProcessed = (result: RegistrationResult) => ({
  type: REGISTRATION_CONTACT_INFORMATION_PROCESSED,
  payload: result,
});

export const REGISTER_CONTACT_INFORMATION = 'REGISTER_CONTACT_INFORMATION' as const;
export const registerContactInformation = (registrationData: RegistrationContactInformationData) => ({
    type: REGISTER_CONTACT_INFORMATION,
    payload: registrationData,
});

export const REGISTRATION_TEMPLATEFIELDS_RECEIVED = 'REGISTRATION_TEMPLATEFIELDS_RECEIVED' as const;
export const templateFieldsReceived = (fields: TemplateFields | null) => ({
  type: REGISTRATION_TEMPLATEFIELDS_RECEIVED,
  payload: fields,
});

export type CreateAccountAction = ReturnType<
  | typeof registrationContactInformationProcessed
  | typeof templateFieldsReceived
  | typeof registerContactInformation
>;