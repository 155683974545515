import type { RouteData } from 'routes';

type VerifyAccountUser = {
    isRegister: boolean;
    isMatchSuccessfull: boolean;
    name: string | null;
    address: string | null;
    accountNo: string | null;
};

type VerifyData = {
    accountNo: string;
    billingZipPostalCode: string;
};

export const VERIFY_ACCOUNT_PROCESSED = 'VERIFY_ACCOUNT_PROCESSED' as const;
export const verifyAccountProcessed = (result: VerifyAccountUser) => ({
    type: VERIFY_ACCOUNT_PROCESSED,
    payload: result,
});

export const VERIFY_ACCOUNT_FAILED = 'VERIFY_ACCOUNT_FAILED' as const;
export const verifyAccountFailed = (result: VerifyAccountUser) => ({ type: VERIFY_ACCOUNT_FAILED, payload: result });

export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT' as const;
export const verifyAccount = (verifyData: VerifyData, redirectTo?: RouteData) => ({ type: VERIFY_ACCOUNT, payload: { verifyData, redirectTo } });

export const VERIFY_ACCOUNT_RESET = 'VERIFY_ACCOUNT_RESET' as const;
export const resetVerifyAccountFailed = () => ({ type: VERIFY_ACCOUNT_RESET });

export type VerifyAccountAction = ReturnType<
    | typeof verifyAccountProcessed
    | typeof verifyAccount
    | typeof resetVerifyAccountFailed
    | typeof verifyAccountFailed
>;