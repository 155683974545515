export const enum FormName {
  Registration = 'registration',
  SubAccount = 'subAccount',
  ChangePassword = 'changePassword',
  ContactUs = 'contactUs',
  Review = 'review',
  ForgotPassword = 'forgotPassword',
  SaveOrderTemplate = 'saveOrderTemplate',
  CreateProspect = 'createProspect',
  EditProfile = 'editProfile',
  CreateDocFreeReturnOrder = 'createDocFreeReturnOrder',
  CreateDocBasedReturnOrder = 'createDocBasedReturnOrder',
  NewsletterSubscribe = 'newsletterSubscribe',
  NewsletterUnsubscribe = 'newsletterUnsubscribe',
  CreateAccountLandingPage = 'createAccountLandingPage',
  ContactInformation = 'contactInformation',
}
