import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';

const Text = ({ isAuthenticated }) => {

const name = useSelector(state => state.user.name);
if(isAuthenticated)
  return <span className="user-name"><b>{name}</b></span>

return <SimpleText textKey={'AccountMenu_Login'} />
};

Text.propTypes = {
  isAuthenticated: PropTypes.bool,
};

export default Text;
