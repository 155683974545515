import { lazy, escapeRegexCharacters } from 'utils/helpers';
import { RouteName } from '../RouteName';
import systemPageRoute from './systemPageRoute';
import routeWithIdSegment from './routeWithIdSegment';
import documentDetailsRoute from './documentDetailsRoute';
import productComparisonRoute from './productComparisonRoute';
import salesAgreementRoute from './salesAgreementRoute';
import productsWithCategoryRoute from './productsWithCategoryRoute';
import type { VirtualPathContext } from './types';
import type { RouteData } from '../types';

const routes = {
  [RouteName.Home]: systemPageRoute('/'),
  [RouteName.BasketPage]: systemPageRoute('/shop/basket', ['page']),
  [RouteName.ContactUs]: systemPageRoute('/contact-us'),
  [RouteName.Login]: systemPageRoute('/profile/login'),
  [RouteName.AzureLoginError]: systemPageRoute('/azure-login-error'), 
  [RouteName.AzureLogin]: systemPageRoute('/azure-login-redirection'), 
  [RouteName.CreateAccountLandingPage]: systemPageRoute('/profile/create-account'),
  [RouteName.VerifyAccount]: systemPageRoute('/profile/verifyAccount'),
  [RouteName.Registration]: systemPageRoute('/profile/register'),
  [RouteName.MyAccount]: systemPageRoute('/profile/'),
  [RouteName.EditProfile]: systemPageRoute('/profile/edit'),
  [RouteName.ChangePassword]: systemPageRoute('/profile/change-password'),
  [RouteName.ForgotPassword]: systemPageRoute('/profile/forgot-password'),
  [RouteName.ResetPassword]: systemPageRoute('/profile/reset-password', ['token']),
  [RouteName.VerifyEmailPreRegisterAccount]: systemPageRoute('/profile/verify-email', ['token']),
  [RouteName.Represent]: systemPageRoute('/profile/represent'),
  [RouteName.SubAccounts]: systemPageRoute('/profile/subaccounts/'),
  [RouteName.NewSubAccount]: systemPageRoute('/profile/subaccounts/create'),
  [RouteName.EditSubAccount]: systemPageRoute('/profile/subaccounts/edit', ['id']),
  [RouteName.Orders]: systemPageRoute('/profile/orders/'),
  [RouteName.Quotes]: systemPageRoute('/profile/quotes/'),
  [RouteName.Invoices]: systemPageRoute('/profile/invoices/'),
  [RouteName.ReturnOrders]: systemPageRoute('/profile/return-orders/'),
  [RouteName.CreateDocFreeReturnOrder]: systemPageRoute('/profile/return-orders/create'),
  [RouteName.CreateDocBasedReturnOrder]: systemPageRoute('/profile/return-orders/create-doc-based', ['id', 'originalOrderId']),
  [RouteName.CreditNotes]: systemPageRoute('/profile/credit-notes/'),
  [RouteName.ReturnReceipts]: systemPageRoute('/profile/return-receipts/'),
  [RouteName.CreateProspect]: systemPageRoute('/profile/createprospect'),
  [RouteName.Shipments]: systemPageRoute('/profile/shipments/'),
  [RouteName.OrderAuthorizations]: systemPageRoute('/profile/order-authorizations'),
  [RouteName.WishList]: systemPageRoute('/profile/wishlist'),
  [RouteName.OrderTemplates]: systemPageRoute('/profile/ordertemplates'),
  [RouteName.SalesAgreements]: systemPageRoute('/profile/sales-agreements/'),
  [RouteName.SalesAgreement]: salesAgreementRoute(),
  [RouteName.BlanketSalesOrderSummary]: systemPageRoute('/profile/blanket-sales-order-summary'),
  [RouteName.NewsOverview]: systemPageRoute('/news/'),
  [RouteName.NewsletterSubscribe]: systemPageRoute('/newsletter/subscribe'),
  [RouteName.NewsletterUnSubscribe]: systemPageRoute('/newsletter/unsubscribe'),
  [RouteName.Search]: systemPageRoute('/search', ['q']),
  [RouteName.LastViewedProducts]: systemPageRoute('/last-viewed-products'),
  [RouteName.ProductsWithCategory]: productsWithCategoryRoute(),
  [RouteName.Checkout]: systemPageRoute('/checkout', ['guest', 'asQuote']),
  [RouteName.QuotePromotion]: systemPageRoute('/promote-quote'),
  [RouteName.QuotePromotionCancelled]: routeWithIdSegment('/promote-quote/cancelled/', 'transactionId'),
  [RouteName.OrderSubmit]: routeWithIdSegment('/checkout/ordersubmit/', 'transactionId'),
  [RouteName.OrderCancelled]: routeWithIdSegment('/checkout/ordercancelled/', 'transactionId'),
  [RouteName.OrderFailed]: routeWithIdSegment('/checkout/orderfailed/', 'transactionId'),
  [RouteName.PaymentError]: routeWithIdSegment('/checkout/paymenterror/', 'transactionId'),
  [RouteName.InvoicePayment]: routeWithIdSegment('/payment/invoice/', 'orderId'),
  [RouteName.OrderPayment]: routeWithIdSegment('/payment/pay/', 'transactionId'),
  [RouteName.PaymentSubmit]: routeWithIdSegment('/payment/pay/submit/', 'transactionId'),
  [RouteName.PaymentCancelled]: routeWithIdSegment('/payment/pay/cancelled/', 'transactionId'),
  [RouteName.PaymentFailed]: routeWithIdSegment('/payment/pay/failed/', 'transactionId'),
  [RouteName.DocumentDetails]: documentDetailsRoute(),
  [RouteName.ProductComparison]: productComparisonRoute(),
  [RouteName.FieldService]: systemPageRoute('/field-service'),
};

type SystemRouteName = keyof typeof routes;
export type SystemRouteData = Omit<RouteData, 'routeName'> & {
  routeName: SystemRouteName;
};

type Options = {
  languageSegment: string | null;
  idEncodings: Array<{ character: string; replacement: string }>;
};

export const urlsBuilder = (options: Options) => {
  const encodeIdLazy = lazy(() => {
    const { idEncodings } = options;
    if (!idEncodings.length)
      return (id: string) => id;

    const replacementMap = new Map<string, string>();
    let characters = '';

    for (const rule of idEncodings) {
      replacementMap.set(rule.character, rule.replacement);
      characters += escapeRegexCharacters(rule.character) + '|';
    }

    const regexp = new RegExp(`[${characters.slice(0, -1)}]`, 'g');
    return (id: string) => id.replace(regexp, c => replacementMap.get(c) || c);
  });

  const context: VirtualPathContext = {
    encodeId: id => encodeIdLazy.value(id),
  };

  return {
    getUrlForRoute(routeData: SystemRouteData): string {
      const route = routes[routeData.routeName];
      if (!route)
        throw new Error(`Route '${routeData.routeName}' is not supported. Use page URL directly or register client route.`);

      const virtualPath = route.getVirtualPath(routeData.params, context);
      if (!options.languageSegment)
        return virtualPath;

      return options.languageSegment + virtualPath;
    },
  };
};
