import { loadCreateAccountPageQuery } from './queries';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import type { Handler } from '../types';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';
import { initSystemPageContent } from '../system';
import type { RegistrationPage, CreateAccountPageResponse, CreateAccountRouteData } from './types';

const handler: Handler<CreateAccountRouteData, RegistrationPage> = (routeData, state$, { api }) => {
    return api.graphApi<CreateAccountPageResponse>(loadCreateAccountPageQuery).pipe(
        map(({ pages, profile }) => {
            const page = pages.verifyAccount;
            if (!page)
                return null;

            const state = state$.value;
            const { accountInformation } = state.verifyAccount;

            page.templateFields = profile.contactInformation;
            page.accountInformation = accountInformation;
            page.component = PageComponentNames.VerifyAccount;
            page.backTo = getBackTo(state$, [
                RouteName.Login,
                RouteName.CreateAccountLandingPage,
            ], routeData.params && routeData.params.language);

            return { page };
        }),
        initSystemPageContent(),
    )
};

export default handler;
