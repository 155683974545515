import type { BlanketSalesOrderSummary } from './types';

export const BLANKET_SALES_SUMMARY_REQUESTED = 'BLANKET_SALES_SUMMARY_REQUESTED' as const;
export const requestBlanketSalesOrderSummary = (index = 0, size = 10) => ({
  type: BLANKET_SALES_SUMMARY_REQUESTED,
  payload: { index, size },
});

export const BLANKET_SALES_SUMMARY_RECEIVED = 'BLANKET_SALES_SUMMARY_RECEIVED' as const;
export const notifyBlanketSalesOrderSummaryReceived = (blanketSalesOrderLineSummary: BlanketSalesOrderSummary[] | null, totalCount = 0, append = false) => ({
  type: BLANKET_SALES_SUMMARY_RECEIVED,
  payload: { blanketSalesOrderLineSummary, totalCount, append },
});

export type BlanketSalesOrderSummaryListRequestAction = ReturnType<typeof requestBlanketSalesOrderSummary>;

export type BlanketSalesOrderSummaryAction = ReturnType<
  | typeof requestBlanketSalesOrderSummary
  | typeof notifyBlanketSalesOrderSummaryReceived
>;
